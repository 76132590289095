/**
 * Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
 */
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr("id"),
        mId = id.substring(id.indexOf("_") + 1),
        messageContainer = $(".message_account_container");

    if (messageContainer.hasClass("appear")) {
        messageContainer.removeClass("appear");
    } else {
        messageContainer.addClass("appear");
    }

    if (!elem.hasClass("actif")) {
        $(".message_detail").slideUp();
        $(".btn_ouvrir_message")
            .not(elem)
            .removeClass("actif");
        $(".btn_ouvrir_message")
            .not(elem)
            .text(msg_open);

        if ($("#msg_txt_" + mId).html() == "") {
            $.ajax({
                type: "post",
                data: {mId: mId, type: 1},
                url: path_relative_root + create_link("message_detail"),
                success: function(response) {
                    $("#msg_txt_" + mId).html(response);
                    $("#msg_" + mId).slideDown();
                },
            });
        } else $("#msg_" + mId).slideDown();
        elem.addClass("actif");
        elem.text(msg_close);
    } else {
        $(".message_detail").slideUp();
        elem.removeClass("actif");
        elem.text(msg_open);
    }
}

$(function() {
    /***@ hover show rollover */
    $(".item .see_more_colors").on({
        mouseover: function() {
            $(this).parents(".item").find(".wrap_rolloverproduit").css({"opacity":"1"});
        },
        mouseout: function() {
            var content = $(this).parents(".item").find(".wrap_rolloverproduit");
            setTimeout(function(){
                content.removeAttr("style");
            },300);
        }
    });
});

//@ INSCRIPTION NEWSLETTER
function exit_overlay_ovi_close() {
    $("#exit_overlay_OVI").removeClass('actif');
    $("#shadCookie").removeClass('actif');
}

function mouseovermorecolorsearch(element) {
    $(element).parents(".item").find(".wrap_rolloverproduit").css({"opacity":"1"});
}

function mouseoutmorecolorsearch(element) {
    var content = $(element).parents(".item").find(".wrap_rolloverproduit");
    setTimeout(function(){
        content.removeAttr("style");
    },2000);
}

$(function() {

    var stickyNavEl = $('#rayon_sticky_nav');
    var backToTopBtn = $('#back_to_top');

    if (stickyNavEl.length > 0) {
        var stickyNavTop = stickyNavEl.offset().top;
    }

    function stickyNav() {
        if (stickyNavEl.length > 0) {
            var scrollTop = $(window).scrollTop(); // our current vertical position from the top

            if (scrollTop > stickyNavTop) {
                stickyNavEl.addClass('sticky');
                $("#cart_top").appendTo(".filters_left");

            } else {
                stickyNavEl.removeClass('sticky');
                $("#cart_top").appendTo(".rightSide");
            }
        }
    };

    stickyNav();

    $(window).scroll(function () {
        stickyNav();

        if ($(window).scrollTop() > 300) {
            backToTopBtn.addClass('show');
        } else {
            backToTopBtn.removeClass('show');
        }
    });

    backToTopBtn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, 500, 'linear');
    });

});

/* MON COMPTE COMMANDE */

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("onglet_" + i + "_mid")) {
        if (type == i) {
            $("#onglet_" + i + "_left").attr("class", "left-on");
            $("#onglet_" + i + "_mid").attr("class", "mid-on");
            $("#onglet_" + i + "_right").attr("class", "right-on");
        } else {
            $("#onglet_" + i + "_left").attr("class", "left-off");
            $("#onglet_" + i + "_mid").attr("class", "mid-off");
            $("#onglet_" + i + "_right").attr("class", "right-off");
        }
        i++;
    }

    var container = document.getElementById(elementID);
    if (container) {
        var response = ajax_file(parentPath + create_link('ajax_account_orders_by_type') + '?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
            return true;
        }
        else{
            container.innerHTML = '';
            return false;
        }
    }
    else
        return false;
}

var global_id_transporteur = '';
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    var divId = 'popup_numero_retour';
    divId += '_'+(multiTransp?idOrderTransp:idOrder);
    var formId = '#return_products_form';
    formId += '_'+(multiTransp?idOrderTransp:idOrder);
    
    openMultiShad(divId);
    swiper_demande_de_retour();
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp)
{
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour+'&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour+'&send_mail=true';
    }

    $.ajax({
        url : path_relative_root + create_link("ajax_create_etiquette"),
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp,idOrderTransp, numRetour) {
    var numRetour = "";
    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
            create_link('ajax_num_etiquette') +
            "?idOrder=" + idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
            );
            else
            numRetour = ajax_file(
            parentPath +
            create_link('ajax_num_etiquette') +
            "?idOrder=" +idOrder +
            "&multiTransp=" +
            multiTransp
        );


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";
        var divCreateNumRetourObjet = document.getElementById("createNumRetour" + transporteur);
        var divPrintPdfObjet = document.getElementById("printPdf" + transporteur);

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var recommand = $(".recommandation").length;

        var has_errors = false;
        $(".return_quantity").each(function() {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0  && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
            }
            showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, false, idOrderTransp);
        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }

    }

    openMultiShad(divId);

}
 function swiper_demande_de_retour() {
    if ($(".swiper_demande_de_retour .swiper-slide").length > 2) {
         swiper_demande_de_retour = new Swiper(".swiper_demande_de_retour", {
             loop: false,
             slidesPerView: 2,
             spaceBetween: 40,
             navigation: {
                nextEl: '.retour_swiper_next',
                prevEl: '.retour_swiper_prev',
            },
         });
     }
}

function close_popup_return(obj_elem) {
    $("#shad").hide();
    $("#popup_numero_retour_" + obj_elem).hide();
    location.reload();
}

/* FIN MON COMPTE */
setTimeout(function(){
    if ($(".edito_swiper .swiper-slide").length > 2) {
        edito_swiper = new Swiper(".edito_swiper", {
             slidesPerView: 2,
             spaceBetween: 7,
             navigation: {
                 nextEl: '.edito_swiper_next',
                 prevEl: '.edito_swiper_prev',
             },
             breakpoints: {
                1190: {
                    slidesPerView: 3,
                    spaceBetween: 7,
                },
                1160: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                },
            },
         });
    }
},500);

$(".lightbox.popup_numero_retour .divBtnPrintPdf").on('click', function (e) {
    $('.lightbox.popup_numero_retour').hide();
});

/***** FAQ Panier animation questions *****/
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}
$(function(){
    setTimeout(function(){
        if (!$(".texte_question").hasClass('actif')) {
            $(".texte_question").slideUp(400);            
        }
    },500);
});

/* Ajouter le texte pour voir le detail des produits dans le recap commande */
window.setTimeout(function() {
    var bill_line_toogle_produit_livraison = $('body.in_tunnel #cart_total .bill_line.total_produit .nb_products');
    var bill_line_toogle_produit_livraison_choix = $('body.in_tunnel #cart_total .bill_line.total_produit .nb_products');
    var bill_line_toogle_produit_updated_line = $('<span class="voir_detail">' + ' ' + Translator.translate('fermer_detail_produit') + '</span>');
    $(bill_line_toogle_produit_updated_line).appendTo(bill_line_toogle_produit_livraison);
    $(bill_line_toogle_produit_updated_line).appendTo(bill_line_toogle_produit_livraison_choix);
}, 500);

/* Ajouter le toggle sur le recap commande pour le total_produit_dropdown */
window.setTimeout(function() {
    var bill_line_total_produit = $('#cart_total .bill_line.total_produit');
    var voir_detail = $('#cart_total .voir_detail');
    $(".total_produit_dropdown").slideToggle();
    $(voir_detail).text(' ' + Translator.translate('voir_detail_produit'));

    $(bill_line_total_produit).unbind( "click" );

    $(bill_line_total_produit).click(function(){
        if ($(bill_line_total_produit).hasClass('open')) {
            $(voir_detail).text(' ' + Translator.translate('voir_detail_produit'));
            $(".total_produit_dropdown").slideToggle();
            $(bill_line_total_produit).removeClass('open');
        } else {
            $(".total_produit_dropdown").slideToggle();
            $(voir_detail).text(' ' + Translator.translate('fermer_detail_produit'));
            $(bill_line_total_produit).addClass('open');
        }
    });
}, 500);

/*afficher warning adresse livraison tunnel bank */
$(function () {
    var txt_warning_delivery = Translator.translate('txt_warning_delivery');
    $(".wrapper_payment").append("<p class='delivery_warning'>" + txt_warning_delivery + "</p>");

});



$(function() {
    $('#selection-offer-content .color_list.swiper-container label').css('border','1px solid gray');
    $('#selection-offer-content .color_list.swiper-container').each(function() {
        var txt_color_cp = Translator.translate('txt_color_cp');
        $(this).prepend("<span class='txt'>" + txt_color_cp + "</span>");
    });

    $('#selection-offer-content .size_list.swiper-container').each(function() {
        var txt_size_cp = Translator.translate('txt_size_cp');
        $(this).prepend("<span class='txt'>" + txt_size_cp + "</span>");
    });
});

$(function() {
    if ($('.product_page #keep_alive_stock #mail_alerte_stock +.w-input-label').length) {
        $('.product_page #keep_alive_stock #mail_alerte_stock +.w-input-label').text(Translator.translate('your_email'));
    }
});

$(function () {
    $('#exit_overlay_OVI .close').on("click", function() {
        $("#shad_abox").hide();
        $("#exit_overlay_OVI").hide();
    });
});

$(function () {
    var cgvText = $('#exit_overlay_OVI .w-adwords-form .pop_cgv_listing');
    var btnSubmit = $('#exit_overlay_OVI .w-adwords-form .w-form-line-container .w-submit');

    if( cgvText.length && btnSubmit.length) {
        cgvText.insertBefore(btnSubmit);
    }
});

$.fn.boom = function(e) {
	var colors = [
		'#FF7870',
		'#FF9999',
		'#FFCCCC',
	];
	var shapes = [
		'<path class="heartFull" d="M9.72,18a.56.56,0,0,1-.39-.17L1.85,10.35a6.13,6.13,0,0,1-.27-8.5,6,6,0,0,1,8.14-.47,6,6,0,0,1,8.14.47,6.14,6.14,0,0,1-.29,8.5l-7.46,7.52a.59.59,0,0,1-.36.13ZM2.37,2.58a5,5,0,0,0,.27,7l7.08,7.12L16.8,9.57a5,5,0,0,0,.27-7,4.9,4.9,0,0,0-7-.06.54.54,0,0,1-.78,0A4.84,4.84,0,0,0,5.89,1.1H5.83A4.77,4.77,0,0,0,2.37,2.58ZM15.75,5.43A2.41,2.41,0,0,0,14,3.7a.55.55,0,0,1-.4-.67.55.55,0,0,1,.68-.4,3.5,3.5,0,0,1,2.52,2.52.55.55,0,0,1-.4.68l-.14,0h0a.54.54,0,0,1-.53-.42Z"/>',
		'<path class="heartOutline" d="M12,22.23h0a.67.67,0,0,1-.48-.2L2.29,12.79A7.51,7.51,0,0,1,0,7.61,7.59,7.59,0,0,1,2,2.29,7.37,7.37,0,0,1,12,1.7,7.41,7.41,0,0,1,16.72,0a7.3,7.3,0,0,1,5.34,2.29,7.57,7.57,0,0,1-.36,10.5l-9.21,9.29A.74.74,0,0,1,12,22.23Zm5.45-19a.72.72,0,0,0-.67.52.69.69,0,0,0,.5.83,3,3,0,0,1,2.13,2.14.68.68,0,0,0,.66.51l.18,0a.7.7,0,0,0,.49-.84,4.36,4.36,0,0,0-3.12-3.12l-.17,0Z"/>',
	];

	var btn = $(this);
	var group = [];
	var num = 20;

	for(i = 0; i < num; i++) {
		var randBG = Math.floor(Math.random() * colors.length);
		var getShape = Math.floor(Math.random() * shapes.length);
		var c = Math.floor(Math.random() * 10) + 5;
		var scale = Math.floor(Math.random() * (8 - 4 + 1)) + 4;
		var x = Math.floor(Math.random() * (150 + 100)) - 100;
		var y = Math.floor(Math.random() * (150 + 100)) - 100;
		var sec = Math.floor(Math.random() * 1700) + 1000;
		var cir = $('<div class="cir"></div>');
		var shape = $('<svg class="shape">'+shapes[getShape]+'</svg>');

		shape.css({
			top: e.pageY - btn.offset().top + 20,
			left: e.pageX - btn.offset().left + 40,
			'transform': 'scale(1.'+scale+')',
			'transition': sec + 'ms',
			'fill': colors[randBG]
		});

		btn.siblings('.btn-particles').append(shape);

		group.push({shape: shape, x: x, y: y});
	}

	for (var a = 0; a < group.length; a++) {
		var shape = group[a].shape;
		var x = group[a].x, y = group[a].y;
		shape.css({
			left: x + 50,
			top: y + 15,
			'transform': 'scale(0)'
		});
	}

	setTimeout(function() {
		for (var b = 0; b < group.length; b++) {
			var shape = group[b].shape;
			shape.remove();
		}
		group = [];
	}, 2000);

}

$(function() {
	$(document).on('click', '#btn_add_cart', function(e) {
		$(this).boom(e);
	});

    if (~window.location.href.indexOf("#livraison") && $('body').hasClass('faq')) {
        $('#theme_page').addClass('actif');
        $('.faq_theme_3').addClass('active');
        $('.faq_question_21').addClass('active');
        $('.faq_questions_3').show().removeClass('cache');
        $('.faq_reponse_21').show().removeClass('cache');

        $("html, body").delay(1000).animate({
            scrollTop: $('.faq_element.active').offset().top -113
        }, 1500);
    }

    if (~window.location.href.indexOf("#return") && $('body').hasClass('faq')) {
        $('#theme_page').addClass('actif');
        $('.faq_theme_4').addClass('active');
        $('.faq_question_35').addClass('active');
        $('.faq_questions_4').show().removeClass('cache');
        $('.faq_reponse_35').show().removeClass('cache');

        $("html, body").delay(1000).animate({
            scrollTop: $('.faq_element.active').offset().top -113
        }, 1500);
    }
});

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + create_link('order_delivery_ajax'),
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.error(e);
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};